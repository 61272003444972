<template>
	<div class="pdf">
	<!-- <a href='http://files.xuruidea.com/%E9%98%B3%E4%BF%A1%E8%AE%A1%E9%87%8F%E5%8D%95%E5%85%83%E6%88%90%E6%9E%9C.pdf'>111</a> -->
		<embed src="/阳信计量单元成果.pdf" type="application/pdf" width="100%" height="800"></embed>
		<!--<<<<<<< HEAD-->
		<!--		&lt;!&ndash; <div class="ccc"></div> &ndash;&gt;-->
		<!--		<embed src="/阳信计量单元成果.pdf" type="application/pdf" width="100%" height="800"></embed>-->
		<!--=======-->
		<!--&lt;!&ndash;		<div class="ccc">  ?page=hsn#toolbar=0&navpanes=0&scrollbar=0 </div>&ndash;&gt;-->
		<!--		<embed src="/阳信计量单元成果.pdf"  type="application/pdf" width="100%" height="800"></embed>-->
		<!--&gt;>>>>>> 2f1f47620b7803f17f5eea877b55b69c0aee6960-->
	</div>
</template>

<script>
	export default {
		name: "viewmap",
		data() {
			return {}
		},
		 created() {
			this.clicks();
		},
		methods: {
			// 直接转二进制下载pdf
			clicks(){
				let url = 'https://files1.xuruidea.com/%E9%98%B3%E4%BF%A1%E8%AE%A1%E9%87%8F%E5%8D%95%E5%85%83%E6%88%90%E6%9E%9C.pdf'
					let name = '阳信计量单元成果.pdf'
					let that=this
					// 发送http请求，将文ss件链接转换成文件流
					this.fileAjax(url, function(xhr) {
					that.downloadFile(xhr.response, name)
					}, { 
						responseType: 'blob' 
					})
			},
			fileAjax(url, callback, options) {
				let xhr = new XMLHttpRequest()
				xhr.open('get', url, true)
				if (options.responseType) {

					xhr.responseType = options.responseType
				}
				xhr.onreadystatechange = function() {
					if (xhr.readyState === 4 && xhr.status === 200) {
						callback(xhr)
					}
				}
				xhr.send()
			},
			downloadFile(content, filename) {
				window.URL = window.URL || window.webkitURL
				let a = document.createElement('a')
				let blob = new Blob([content])
			// 通过二进制文件创建url
				let url = window.URL.createObjectURL(blob)
				a.href = url
				a.download = filename
				a.click()
			// 销毁创建的url
				window.URL.revokeObjectURL(url)
			}
		}

	}
</script>

<style lang="scss" scoped>
	.pdf {
		width: 100%;
		height: 100%;
		/*position: relative;*/
		/*.ccc{*/
		/*	position: absolute;*/
		/*	top: 0px;*/
		/*	width: 100%;*/
		/*	height: 58px;*/
		/*	background-color: #eff2f5;*/
		/*}*/

		img {
			width: 1200px;
			height: 1000px;
			max-width: 100%;
			max-height: 100%;
			/*background: ;*/
		}
	}

</style>
